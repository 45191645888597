<template>
  <div>
    <title>SIM INVENTORY ~ ADD DATA FINISH GOOD DETAIL SOURCE PRODUCTION PROCESS</title>
    <section class="content-header">
      <h1>
        Add Data Finish Good Detail Source Production Process
        <br />
        <h4>
          Please enter transaction data Finish Good Detail Source Production Process
        </h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">
          Transaction Finish Good Detail Source Production Process
        </li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">
                Data Finish Good Detail Source Production Process
              </h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Receipt Number</label>
                  <input
                    type="text"
                    v-model="isidata.receiptnumb"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Receipt Number"
                  />
                  <input
                    type="hidden"
                    v-model="isidata.idxprosesproduksi"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="isidata.notesprosesprod"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty</label>
                  <input
                    type="number"
                    v-model="isidata.qtyprosprod"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Qty"
                  />
                  <input
                    type="hidden"
                    v-model="isidata.qtyprosprodi"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Qty"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Unit</label>
                  <input
                    type="text"
                    v-model="isidata.unitfgdpenerimaansumberinternal"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Unit"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="saveData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Save
                </button>
                &nbsp;
                <router-link to="/finishgood">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "addmasterdata",
  data() {
    return {
      loading: false,
      isidata: {
        receiptnumb: "",
        idxprosesproduksi: "",
        notesprosesprod: "",
        qtyprosprod: "",
        qtyprosprodi: "",
        unitfgdpenerimaansumberinternal: "",
      },
    };
  },
  created() {
    this.fetchstok();
    this.isidata.idxprosesproduksi = this.$route.params.id;
  },
  methods: {
    fetchstok() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "proses_produksi_d_dari_proses_produksi/getstok_hasilk_produksibyid?id=" +
        this.$route.params.id;
      console.log(urlAPIget);
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin,
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          //   console.log(resp);
          if (resp.data.pesan == "Data not found") {
            swal({
              icon: "warning",
              title: "Warning",
              text: "Sorry, data not found",
              showConfirmButton: false,
            });
          } else {
            this.isidata.idxprosesproduksi = resp.data.data.id;
            this.isidata.qtyprosprod = resp.data.data.stok;
            this.isidata.qtyprosprodi = resp.data.data.stok;
            this.loading = false;
          }
        })
        /*  .catch((err) => {
          // console.log(err.response);
          swal({
            icon: "warning",
            title: "Peringatan",
            text: err.response.data,
          }).then(function (isConfirm) {
            if (isConfirm) {
              window.location.href = "/";
            }
          });
        }); */
        .catch((err) => {
          // console.log(err.response);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data,
          });
        });
    },
    async saveData() {
      this.loading = true;
      var recnumber = this.isidata.receiptnumb;
      var catatan = this.isidata.notesprosesprod;
      var jmlqty = this.isidata.qtyprosprod;
      var unitd = this.isidata.unitfgdpenerimaansumberinternal;
      if (recnumber == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Receipt Number can't be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else if (catatan == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes Finish Good can't be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else if (jmlqty == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty Finish Good Detail can't be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else if (unitd == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Unit Finish Good Detail can't be empty",
          showConfirmButton: false,
        });
        this.loading = false;
      } else {
        if (this.isidata.qtyprosprod > this.isidata.qtyprosprodi) {
          swal({
            icon: "warning",
            title: "Peringatan",
            text: "Sorry, the amount you entered exceeds the existing stock",
            showConfirmButton: false,
          });
          this.loading = false;
        } else {
          const kodeuser = sessionStorage.getItem("kodeuser");
          const paramdata = {
            receipt_number: this.isidata.receiptnumb,
            id_nomor_proses_produksi: this.isidata.idxprosesproduksi,
            notes: this.isidata.notesprosesprod,
            qty: this.isidata.qtyprosprod,
            kode_user: kodeuser,
            unit: this.isidata.unitfgdpenerimaansumberinternal,
          };
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          const urlAPISaveData =
            this.$apiurl +
            "penerimaan_fg_d/savepenerimaan_fg_d_sumber_internal";
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin,
          };
          axios
            .post(urlAPISaveData, paramdata, { headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Save data successfully",
                  showConfirmButton: false,
                });
                this.$router.push({ name: "finishgoodheader" });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Save data failed",
                  showConfirmButton: false,
                });
                this.loading = false;
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data,
                showConfirmButton: false,
              });
              console.log(err);
              this.loading = false;
            });
        }
      }
    },
  },
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
